import { BenefitTemplate, EnergoCalculationSource, EnergoCalculationType, MortgagePurpose } from "@gql-schema";
import { ReturnLinkSourceType } from "@ui/components/return-link/return-link";

export type Routes = {
    index: null;
    about: null;
    cookies: null;
    "terms-and-conditions": null;
    "personal-data-processing": null;
    logout: { redirect?: string };
    "logout-admin": {
        redirect?: string;
    };
    example: null;
    sitemap: null;
    "web-sitemap": null;
    rescue: null;
    "sitemap-json": null;

    // energo
    "energo-zone/entry-page": {
        type?: EnergoCalculationType;
    };
    "energo-zone/distributors": null;
    "energo-zone/gas-distributors": null;
    "energo-zone/electricity-distributors": null;
    "energo-zone/distributor-detail": {
        source?: ReturnLinkSourceType;
        seoNameAndPartnerId: string;
    };
    "energo-zone/gas-distributor-detail": {
        source?: ReturnLinkSourceType;
        seoNameAndPartnerId: string;
    };
    "energo-zone/electricity-distributor-detail": {
        source?: ReturnLinkSourceType;
        seoNameAndPartnerId: string;
    };
    "energo-zone/gas-tariff-detail": {
        seoNameAndPartnerId: string;
        tariffNameAndTariffId: string;
        source?: ReturnLinkSourceType;
    };
    "energo-zone/electricity-tariff-detail": {
        seoNameAndPartnerId: string;
        tariffNameAndTariffId: string;
        source?: ReturnLinkSourceType;
    };
    "energo-zone/calculation-link": {
        profileUuid: string;
        calculationUuid: string;
    };
    "energo-zone/consumption": {
        uuid: string;
    };
    "energo-zone/change-calculation-source": {
        uuid: string;
        source: EnergoCalculationSource;
    };
    "energo-zone/calculation-result": {
        uuid: string;
        resultId?: string;
        filter?: string;
    };
    "energo-zone/calculation-lead": {
        leadUuid: string;
    };
    "energo-zone/calculation-contract": {
        calculationUuid: string;
    };
    "energo-zone/thank-you": {
        leadUuid: string;
    };
    "energo-zone/standalone-invoice-create": {
        type: "elektrina" | "plyn";
    };
    "energo-zone/distributor-comparison": {
        resultItemId: string;
        type: EnergoCalculationType;
        uuid: string;
        filename?: string;
    };
    "energo-zone/offer-overview": {
        resultItemId: string;
        type: EnergoCalculationType;
        uuid: string;
        filename?: string;
    };
    "energo-zone/standalone-document-upload": {
        campaign?: string;
        type: "smlouva" | "faktura";
    };
    "energo-zone/electricity-form-banner": {
        monthlyAdvances: number;
    };

    // finance
    "finance-zone/entry": null;
    "finance-zone/calculation-results": { uuid: string };
    "finance-zone/calculation-go-to-product-web": { uuid?: string; productId: number };
    "finance-zone/lead-create": { uuid: string; productId: number };
    "finance-zone/partner-detail": { partnerSlug: string };
    "finance-zone/product-detail": { partnerSlug: string; productSlug: string; calculationUuid?: string };
    "finance-zone/thank-you": { uuid: string };
    "finance-zone/exception": null;
    "finance-zone/thank-you-crash": { uuid: string };

    // finance - pujcky
    "finance-zone/loans": null;
    "finance-zone/loans-calculation-form": { uuid: string; purpose?: number };
    "finance-zone/loans-calculation-create-standalone": null;

    // finance - konsolidace
    "finance-zone/consolidation": null;
    "finance-zone/consolidation-calculation-form": { uuid: string; debtsCount?: number; needExtraLoan?: number };
    "finance-zone/consolidation-calculation-create-standalone": null;

    // finance-zone - refinancovani
    "finance-zone/refinancing": null;
    "finance-zone/refinancing-calculation-form": {
        uuid: string;
        refinancingRemainingAmount?: number;
        needExtraLoan?: number;
    };
    "finance-zone/refinancing-calculation-create-standalone": null;

    // finance-zone - hypoteky
    "finance-zone/mortgage": null;
    "finance-zone/mortgage-calculation-form": { uuid: string; mortgagePurpose?: MortgagePurpose };
    "finance-zone/mortgage-calculation-create-standalone": null;

    // customer
    "customer-zone/login-email": {
        email?: string;
    };
    "customer-zone/login-password": {
        email: string;
    };
    "customer-zone/registration": {
        email?: string;
    };
    "customer-zone/link-sent": {
        email: string;
        forgottenPassword?: boolean;
    };
    "customer-zone/benefits": null;
    "customer-zone/benefit": { uuid: string };
    "customer-zone/benefit-thank-you": { variant: BenefitTemplate };
    "customer-zone/summary-landing": {
        calculationUuid: string;
    };
    "customer-zone/intro": {
        url: string;
    };
    "customer-zone/calculations": {
        calculationUuid?: string;
        passwordChange?: boolean;
        tab?: string;
    };
    "customer-zone/contracts": {
        orderId?: string;
    };
    "customer-zone/personal-data": null;
    "customer-zone/watchwolf": {
        scrollToUuid?: string;
        showModal?: boolean;
    };
    "customer-zone/energo-offer": {
        uuid: string;
        offerId?: number;
    };
    "customer-zone/energo-contract-detail": {
        calculationUuid: string;
    };
    "customer-zone/vehicle-insurance-contract-detail": {
        orderUuid: string;
    };
    "customer-zone/travel-insurance-contract-detail": {
        orderUuid: string;
    };

    // blog
    "blog-zone/article": {
        articleId: number;
        slug?: string;
    };
    "blog-zone/article-preview": {
        articleId: number;
    };
    "blog-zone/category": {
        categoryId: number;
    };
    "blog-zone/home": null;
    "blog-zone/news": null;
    "blog-zone/search": null;
    "blog-zone/search-result": {
        searchTerm?: string;
    };

    // knowledgebase
    "knowledgebase-zone/home": null;
    "knowledgebase-zone/concepts": null;
    "knowledgebase-zone/energy-concepts": null;
    "knowledgebase-zone/finance-concepts": null;
    "knowledgebase-zone/insurance-concepts": null;
    "knowledgebase-zone/category": { categoryId: number; slug?: string };
    "knowledgebase-zone/detail": { articleId: number; slug?: string };
    "knowledgebase-zone/fulltext": { term: string };

    // insurance entry page
    "insurance-entry-page": null;

    // property insurance zone
    "property-insurance-zone/entry-page": null;
    "property-insurance-zone/household-entry-page": null;
    "property-insurance-zone/property-entry-page": null;
    "property-insurance-zone/information-form": {
        uuid: string;
    };
    "property-insurance-zone/results": {
        uuid: string;
    };
    "property-insurance-zone/inquiry": {
        uuid: string;
    };
    "property-insurance-zone/thank-you": {
        uuid: string;
    };

    // travel insurance zone
    "travel-insurance-zone/entry-page": null;
    "travel-insurance-zone/information-form": {
        uuid: string;
    };
    "travel-insurance-zone/calculation-result": {
        uuid: string;
    };
    "travel-insurance-zone/upsell": {
        uuid: string;
    };
    "travel-insurance-zone/lead-form": {
        uuid: string;
    };
    "travel-insurance-zone/exception": {
        type?: string;
    };
    "travel-insurance-zone/thank-you": {
        uuid: string;
    };
    "travel-insurance-zone/thank-you-crash": {
        uuid: string;
    };

    // vehicle insurance zone
    "vehicle-insurance-zone/entry-page": null;
    "vehicle-insurance-zone/information-form": { uuid: string };
    "vehicle-insurance-zone/calculation-result": { uuid: string };
    "vehicle-insurance-zone/calculation-lead": {
        uuid: string;
        showStep?: string;
    };
    "vehicle-insurance-zone/thank-you": {
        uuid: string;
    };
    "vehicle-insurance-zone/thank-you-phone": {
        uuid: string;
    };
    "vehicle-insurance-zone/thank-you-crash": {
        uuid: string;
    };
    "vehicle-insurance-zone/recalculation-crash": {
        uuid: string;
    };
    "vehicle-insurance-zone/insurance-companies": null;
    "vehicle-insurance-zone/company-detail": {
        seoNameAndPartnerId: string;
        source?: ReturnLinkSourceType;
    };
    "vehicle-insurance-zone/product-detail": {
        seoNameAndPartnerId: string;
        productNameAndProductId: string;
        source?: ReturnLinkSourceType;
    };
    "vehicle-insurance-zone/insurance-order-link": {
        profileUuid: string;
        orderUuid: string;
    };
    "vehicle-insurance-zone/exception": {
        type?: string;
    };
    "vehicle-insurance-zone/form-banner": {
        registrationNumber: string;
        zeteoAddress: string;
    };
    "vehicle-insurance-zone/contract-termination": {
        insuranceCompany: string;
    };

    // telco zone
    "telco-zone/entry": null;
    // TODO: remove "edit", take it from calculation
    "telco-zone/calculation-form": { uuid: string; edit?: number };
    "telco-zone/upsell-form": { uuid: string; edit?: number };
    "telco-zone/calculation-results": { uuid: string };
    "telco-zone/lead-create": { uuid: string };
    "telco-zone/thank-you": { uuid: string };

    // landing page zone
    "landing-page-zone/landing": { id: string; seoName?: string };
    "landing-page-zone/landing-preview": { id: string };
    "landing-page-zone/gas": null;
    "landing-page-zone/electricity": null;
    "landing-page-zone/cheap-electricity": null;
    "landing-page-zone/pov": null;
    "landing-page-zone/hav": null;
    "landing-page-zone/car-insurance": null;
    "landing-page-zone/motorcycle-insurance": null;
    "landing-page-zone/electricity-calculator": null;
    "landing-page-zone/gas-calculator": null;
    "landing-page-zone/pov-calculator": null;
    "landing-page-zone/hav-calculator": null;
    "landing-page-zone/motorcycle-insurance-calculator": null;
    "landing-page-zone/get-thousand-campaign": null;
    "landing-page-zone/travel-insurance": null;
    "landing-page-zone/property-insurance": null;
    "landing-page-zone/property-household-insurance": null;
    "landing-page-zone/property-estate-insurance": null;
    "landing-page-zone/wolf-question-send": null;
    "landing-page-zone/electricity-price-increase": null;
    "landing-page-zone/green-electricity": null;
    "landing-page-zone/electricity-heating": null;
    "landing-page-zone/car-contract": null;
    "landing-page-zone/car-poa": null;
    "landing-page-zone/loan-online": null;
    "landing-page-zone/car-loan": null;
    "landing-page-zone/bank-loan": null;
    "landing-page-zone/non-bank-loan": null;
    "landing-page-zone/reconstruction-loan": null;
    "landing-page-zone/price-per-kwh-electricity": null;
    "landing-page-zone/price-per-m3-gas": null;
    "landing-page-zone/electricity-price-history": null;
    "landing-page-zone/electricity-price-list": null;
    "landing-page-zone/gas-price-list": null;

    // watch wolf zone
    "watch-wolf-zone/entry-page": null;
};

export const routes: { [route in keyof Routes]: string } = {
    index: "/",
    cookies: "/cookies",
    about: "/o-nas",
    "terms-and-conditions": "/podminky-portalu",
    "personal-data-processing": "/zpracovani-osobnich-udaju",

    logout: "/logout",
    "logout-admin": "/logout-admin",
    sitemap: "/sitemap.xml",
    "web-sitemap": "/mapa-stranek",
    "sitemap-json": "/sitemap.json",

    // energo-zone
    // priklad routovani /test/:seoName(.*-)?:partnerId(\d+)
    // vyhoda: seoName je nepovinne - funguje /test/123 i /test/bla-bla-bla-123
    // nevyhoda: seoName obsahuje (musi obsahovat) pomlcku na konci: bla-bla-bla-
    // nevim, jak udelat, aby pomlcka nebyla v :seoName
    //
    // dulezite odkazy:
    // - http://forbeslindesay.github.io/express-route-tester/
    // - https://github.com/pillarjs/path-to-regexp
    // - https://github.com/fridays/next-routes
    "energo-zone/entry-page": "/energie",
    "energo-zone/distributors": "/dodavatele-energie",
    "energo-zone/gas-distributors": "/dodavatele-plyn",
    "energo-zone/electricity-distributors": "/dodavatele-elektrina",
    "energo-zone/distributor-detail": "/dodavatele-energie/[seoNameAndPartnerId]",
    "energo-zone/gas-distributor-detail": "/dodavatele-plyn/[seoNameAndPartnerId]",
    "energo-zone/electricity-distributor-detail": "/dodavatele-elektrina/[seoNameAndPartnerId]",
    "energo-zone/gas-tariff-detail": "/dodavatele/plyn/[seoNameAndPartnerId]/[tariffNameAndTariffId]",
    "energo-zone/electricity-tariff-detail": "/dodavatele/elektrina/[seoNameAndPartnerId]/[tariffNameAndTariffId]",
    "energo-zone/calculation-link": "/calculation-link/[profileUuid]/[calculationUuid]",
    "energo-zone/consumption": "/kalkulace/[uuid]/vase-spotreba",
    "energo-zone/change-calculation-source": "/kalkulace/[uuid]/zmena-zdroje",

    "energo-zone/calculation-result": "/kalkulace/[uuid]/vysledek",
    "energo-zone/calculation-lead": "/sjednani/[leadUuid]",
    "energo-zone/calculation-contract": "/smlouva/[calculationUuid]",
    "energo-zone/thank-you": "/odeslano/[leadUuid]",
    "energo-zone/standalone-invoice-create": "/nahrat-fakturu/[type]/nahrat",
    "energo-zone/distributor-comparison": "/kalkulace/[uuid]/vysledek/srovnani-dodavatelu",
    "energo-zone/offer-overview": "/kalkulace/[uuid]/vysledek/detail-nabidky",
    "energo-zone/standalone-document-upload": "/nahrat-dokument/[type]",
    "energo-zone/electricity-form-banner": "/hledani-tarifu-elektriny",

    // finance-zone
    "finance-zone/entry": "/finance",
    "finance-zone/calculation-results": "/pujcky/[uuid]/vysledky",
    "finance-zone/calculation-go-to-product-web": "/pujcky/produkt/prejit-na-web",
    "finance-zone/lead-create": "/pujcky/[uuid]/produkt/[productId]/nezavisla-poptavka",
    "finance-zone/partner-detail": "/pujcky/partner/[partnerSlug]",
    "finance-zone/product-detail": "/pujcky/partner/[partnerSlug]/produkt/[productSlug]",
    "finance-zone/thank-you": "/pujcky/[uuid]/dekujeme",
    "finance-zone/exception": "/pujcky/neco-se-nepovedlo",
    "finance-zone/thank-you-crash": "/pujcky/[uuid]/nezdarene-sjednani",

    // finance-zone - pujcky
    "finance-zone/loans": "/pujcky",
    "finance-zone/loans-calculation-form": "/pujcky/[uuid]/informace",
    "finance-zone/loans-calculation-create-standalone": "/kalkulace-pujcky",

    // finance-zone - konsolidace
    "finance-zone/consolidation": "/konsolidace",
    "finance-zone/consolidation-calculation-form": "/konsolidace/[uuid]/informace",
    "finance-zone/consolidation-calculation-create-standalone": "/kalkulace-konsolidace",

    // finance-zone - refinancovani
    "finance-zone/refinancing": "/refinancovani",
    "finance-zone/refinancing-calculation-form": "/refinancovani/[uuid]/informace",
    "finance-zone/refinancing-calculation-create-standalone": "/kalkulace-refinancovani",

    // finance-zone - hypoteky
    "finance-zone/mortgage": "/hypoteky",
    "finance-zone/mortgage-calculation-form": "/hypoteky/[uuid]/informace",
    "finance-zone/mortgage-calculation-create-standalone": "/kalkulace-hypoteky",

    // telco-zone
    "telco-zone/entry": "/tarify-internet-tv",
    "telco-zone/calculation-form": "/tarify-internet-tv/[uuid]/informace",
    "telco-zone/upsell-form": "/tarify-internet-tv/[uuid]/upsell",
    "telco-zone/calculation-results": "/tarify-internet-tv/[uuid]/vysledky",
    "telco-zone/lead-create": "/tarify-internet-tv/[uuid]/nezavazna-poptavka",
    "telco-zone/thank-you": "/tarify-internet-tv/[uuid]/dekujeme",

    // customer-zone
    "customer-zone/login-email": "/ucet/prihlaseni",
    "customer-zone/login-password": "/ucet/prihlaseni-heslem",
    "customer-zone/registration": "/ucet/registrace",
    "customer-zone/link-sent": "/ucet/odkaz",
    "customer-zone/summary-landing": "/zakaznicka-zona/prehled-z-emailu/[calculationUuid]",
    "customer-zone/intro": "/zakaznicka-zona/intro",
    "customer-zone/calculations": "/zakaznicka-zona/vase-srovnani",
    "customer-zone/contracts": "/zakaznicka-zona/smlouvy",
    "customer-zone/personal-data": "/zakaznicka-zona/osobni-udaje",
    "customer-zone/benefits": "/zakaznicka-zona/odmeny",
    "customer-zone/benefit": "/zakaznicka-zona/odmena/[uuid]",
    "customer-zone/benefit-thank-you": "/zakaznicka-zona/odmena-odeslana",
    "customer-zone/watchwolf": "/zakaznicka-zona/hlidaci-vlk",
    "customer-zone/energo-offer": "/zakaznicka-zona/detail-nabidky/[uuid]",
    "customer-zone/energo-contract-detail": "/zakaznicka-zona/energie/detail-smlouvy/[calculationUuid]",
    "customer-zone/vehicle-insurance-contract-detail": "/zakaznicka-zona/pojisteni-vozidla/detail-smlouvy/[orderUuid]",
    "customer-zone/travel-insurance-contract-detail": "/zakaznicka-zona/cestovni-pojisteni/detail-smlouvy/[orderUuid]",

    // blog-zone
    "blog-zone/home": "/clanky",
    "blog-zone/news": "/clanky/aktuality",
    "blog-zone/search": "/clanky/vyhledavani",
    "blog-zone/search-result": "/clanky/vysledky-vyhledavani",
    "blog-zone/category": "/clanky/kategorie/[categoryId]",
    "blog-zone/article": "/clanky/[articleId]/[slug]",
    "blog-zone/article-preview": "/clanky/nahled/[articleId]",

    // knowledgebase-zone
    "knowledgebase-zone/home": "/faq",
    "knowledgebase-zone/concepts": "/slovnik-pojmu",
    "knowledgebase-zone/energy-concepts": "/pojmy-energie",
    "knowledgebase-zone/finance-concepts": "/pojmy-pujcky",
    "knowledgebase-zone/insurance-concepts": "/pojmy-pojisteni",
    "knowledgebase-zone/category": "/faq/kategorie/[categoryId]/[slug]",
    "knowledgebase-zone/detail": "/faq/otazka/[articleId]/[slug]",
    "knowledgebase-zone/fulltext": "/faq/vysledky-vyhledavani",

    // insurance entry page
    "insurance-entry-page": "/pojisteni",

    // property insurance zone
    "property-insurance-zone/entry-page": "/pojisteni-majetku/kalkulacka",
    "property-insurance-zone/household-entry-page": "/pojisteni-domacnosti/kalkulacka",
    "property-insurance-zone/property-entry-page": "/pojisteni-nemovitosti/kalkulacka",
    "property-insurance-zone/information-form": "/pojisteni-majetku/[uuid]/informace",
    "property-insurance-zone/results": "/pojisteni-majetku/[uuid]/vysledek",
    "property-insurance-zone/inquiry": "/pojisteni-majetku/[uuid]/poptavka",
    "property-insurance-zone/thank-you": "/pojisteni-majetku/[uuid]/odeslano",

    // travel insurance zone
    "travel-insurance-zone/entry-page": "/cestovni-pojisteni/kalkulacka",
    "travel-insurance-zone/information-form": "/cestovni-pojisteni/[uuid]/o-vasi-ceste",
    "travel-insurance-zone/calculation-result": "/cestovni-pojisteni/[uuid]/kalkulace",
    "travel-insurance-zone/upsell": "/cestovni-pojisteni/[uuid]/doporucena-nabidka",
    "travel-insurance-zone/lead-form": "/cestovni-pojisteni/[uuid]/objednavka",
    "travel-insurance-zone/exception": "/cestovni-pojisteni/neco-se-nepovedlo",
    "travel-insurance-zone/thank-you": "/cestovni-pojisteni/[uuid]/odeslano",
    "travel-insurance-zone/thank-you-crash": "/cestovni-pojisteni/[uuid]/nezdarene-sjednani",

    // vehicle insurance zone
    "vehicle-insurance-zone/entry-page": "/pojisteni-auta/kalkulacka",
    "vehicle-insurance-zone/information-form": "/pojisteni/[uuid]/o-vozidle",
    "vehicle-insurance-zone/calculation-result": "/pojisteni/[uuid]/kalkulace",
    "vehicle-insurance-zone/calculation-lead": "/pojisteni/[uuid]/objednavka",
    "vehicle-insurance-zone/thank-you": "/pojisteni/[uuid]/odeslano",
    "vehicle-insurance-zone/thank-you-phone": "/pojisteni/[uuid]/odeslano-telefon",
    "vehicle-insurance-zone/thank-you-crash": "/pojisteni/[uuid]/nezdarene-sjednani",
    "vehicle-insurance-zone/recalculation-crash": "/pojisteni/[uuid]/nezdarena-poptavka",
    "vehicle-insurance-zone/insurance-companies": "/pojistovny",
    "vehicle-insurance-zone/company-detail": "/pojistovny/[seoNameAndPartnerId]",
    "vehicle-insurance-zone/product-detail": "/pojistovny/[seoNameAndPartnerId]/[productNameAndProductId]",
    "vehicle-insurance-zone/insurance-order-link": "/insurance-order-link/[profileUuid]/[orderUuid]",
    "vehicle-insurance-zone/exception": "/pojisteni/neco-se-nepovedlo",
    "vehicle-insurance-zone/form-banner": "/hledani-pojisteni-auta",
    "vehicle-insurance-zone/contract-termination": "/vypoved-povinneho-ruceni",

    // landing page zone
    "landing-page-zone/landing": "/lp/[id]/[seoName]",
    "landing-page-zone/landing-preview": "/lp-nahled/[id]",
    "landing-page-zone/electricity": "/elektrina",
    "landing-page-zone/gas": "/plyn",
    "landing-page-zone/cheap-electricity": "/nejlevnejsi-elektrina",
    "landing-page-zone/pov": "/povinne-ruceni",
    "landing-page-zone/hav": "/havarijni-pojisteni",
    "landing-page-zone/car-insurance": "/pojisteni-auta",
    "landing-page-zone/motorcycle-insurance": "/pojisteni-motocyklu",
    "landing-page-zone/electricity-calculator": "/elektrina/kalkulace",
    "landing-page-zone/gas-calculator": "/plyn/kalkulace",
    "landing-page-zone/pov-calculator": "/povinne-ruceni/kalkulacka",
    "landing-page-zone/hav-calculator": "/havarijni-pojisteni/kalkulacka",
    "landing-page-zone/motorcycle-insurance-calculator": "/pojisteni-motocyklu/kalkulacka",
    "landing-page-zone/get-thousand-campaign": "/ziskej-1000-kc",
    "landing-page-zone/travel-insurance": "/cestovni-pojisteni",
    "landing-page-zone/property-insurance": "/pojisteni-nemovitosti",
    "landing-page-zone/property-household-insurance": "/pojisteni-domacnosti",
    "landing-page-zone/property-estate-insurance": "/pojisteni-majetku",
    "landing-page-zone/wolf-question-send": "/otazka-odeslana",
    "landing-page-zone/electricity-price-increase": "/zdrazeni-elektriny",
    "landing-page-zone/green-electricity": "/zelena-elektrina",
    "landing-page-zone/electricity-heating": "/topeni-elektrinou",
    "landing-page-zone/car-contract": "/kupni-smlouva-automobilu-2022",
    "landing-page-zone/car-poa": "/plna-moc-prepisu-vozidla-2022",
    "landing-page-zone/loan-online": "/pujcka-online",
    "landing-page-zone/car-loan": "/pujcka-na-auto",
    "landing-page-zone/bank-loan": "/bankovni-pujcky",
    "landing-page-zone/non-bank-loan": "/nebankovni-pujcky",
    "landing-page-zone/reconstruction-loan": "/pujcka-na-rekonstrukci",
    "landing-page-zone/price-per-kwh-electricity": "/cena-za-1-kwh-elektriny",
    "landing-page-zone/price-per-m3-gas": "/cena-za-m3-plynu",
    "landing-page-zone/electricity-price-history": "/vyvoj-cen-elektriny",
    "landing-page-zone/electricity-price-list": "/ceniky-elektriny",
    "landing-page-zone/gas-price-list": "/ceniky-plynu",

    // watch wolf zone
    "watch-wolf-zone/entry-page": "/hlidaci-vlk",

    // test
    example: "/example",
    rescue: "/rescue",
};
